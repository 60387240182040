import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const ArtDirectedBridgeTest = () => {
  const [pluginImage, setPluginImage] = useState<BgImage | null>(null);
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: {
            eq: "stock-photo-new-energy-solar-energy-in-sunny-day-1265049448.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              webpOptions: { quality: 70 }
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );

  useEffect(() => {
    setPluginImage(getImage(placeholderImage));
  }, [placeholderImage]);

  return (
    <BgImage image={pluginImage} className="w-full h-full">
      Test
    </BgImage>
  );
};

export default ArtDirectedBridgeTest;
